import { createUserManager } from 'redux-oidc'
import { IOidcConfig } from '../config.d'

export const getClientSettings = (
  oidcUrl: string,
  path: string,
  oidcLinks: IOidcConfig['links'],
  oidcCerts: IOidcConfig['certs'],
  kcIdpHint: string,
) =>
  createUserManager({
    accessTokenExpiringNotificationTime: 30,
    authority: oidcUrl,
    automaticSilentRenew: true,
    client_id: 'melding-til-bruker-ui',
    filterProtocolClaims: true,
    loadUserInfo: true,
    post_logout_redirect_uri: `${window.location.protocol}//${
      window.location.hostname
    }${window.location.port ? `:${window.location.port}` : ''}/logout/callback`,
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ''
    }/login/callback`,
    response_type: 'id_token token',
    revokeAccessTokenOnSignout: true,
    scope: 'openid profile',
    silent_redirect_uri: `${window.location.protocol}//${
      window.location.hostname
    }${
      window.location.port ? `:${window.location.port}` : ''
    }/silent-callback.html`,
    metadata: oidcLinks,
    signingKeys: oidcCerts,
    extraQueryParams: {
      kc_idp_hint: kcIdpHint,
    },
  })
