import React from 'react'
import { ISlakteri, ISlakteriListByRegion } from '../../ducks/slakterier/types'
import { SelectList, Row, Button, Text, Column } from '@mattilsynet/mt-ui'
import { ModalWrapper } from '../modal-wrapper'

interface ISelectSlakterierModalProps {
  isOpen: boolean
  onCancel: () => void
  selectedSlakterier: ISlakteri[]
  onSelectSlakterier: (selectedSlakterier: ISlakteri[]) => void
  alleSlakterier: ISlakteri[]
  alleSlakterierSelected: boolean
  slakterierGroupedByRegion: ISlakteriListByRegion
}

export const SelectSlakterierModal = ({
  isOpen,
  onCancel,
  selectedSlakterier,
  onSelectSlakterier,
  slakterierGroupedByRegion,
  alleSlakterier,
  alleSlakterierSelected,
}: ISelectSlakterierModalProps) => {
  return (
    <ModalWrapper
      onCancel={onCancel}
      isOpen={isOpen}
      title="Slakterier meldingen gjelder"
      buttonOkText="Bruk slakterier"
      onOk={onCancel}
      paddingHorizontal={0}
    >
      <Column as="ul" padding={[1, 0]}>
        <Row padding={[0, 2.5]} margin={[2, 0]} as="li" justify="space-between">
          <Text size="heading3" weight="bold">
            Alle slakterier
          </Text>
          <Button
            onClick={() => {
              if (alleSlakterierSelected) {
                onSelectSlakterier([])
              } else {
                onSelectSlakterier(alleSlakterier)
              }
            }}
            width="8em"
            secondary={!alleSlakterierSelected}
            small
            ariaSelected={alleSlakterierSelected}
          >
            {alleSlakterierSelected ? 'Valgt' : 'Velg'}
          </Button>
        </Row>
        {slakterierGroupedByRegion.map(([region, slakterier]) => {
          const dataList = slakterier.map((slakteri: ISlakteri) => ({
            value: slakteri.idstring!,
            label: `${slakteri.eftaNumber || ''} - ${slakteri.navn}`,
          }))
          return (
            <Column as="li" key={region} padding={[2, 0]}>
              <Text size="heading3" weight="bold" margin={[0, 2.5, 2, 2.5]}>
                {region}
              </Text>
              <SelectList
                dataList={dataList}
                selected={selectedSlakterier.map(
                  (slakteri) => slakteri.idstring!,
                )}
                onClick={(selectedValue) => {
                  const selectedSlakteri = slakterier.find(
                    (slakteri) => slakteri.idstring === selectedValue,
                  )
                  const isSelected = selectedSlakterier.some(
                    (slakteri) => slakteri.idstring === selectedValue,
                  )
                  if (isSelected) {
                    onSelectSlakterier(
                      selectedSlakterier.filter(
                        (slakteri) => slakteri.idstring !== selectedValue,
                      ),
                    )
                  } else if (selectedSlakteri) {
                    onSelectSlakterier([
                      ...selectedSlakterier,
                      selectedSlakteri,
                    ])
                  }
                }}
              />
            </Column>
          )
        })}
      </Column>
    </ModalWrapper>
  )
}
