import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  Table,
  TableRow,
  TableCell,
  Column,
  Text,
  LoadingSpinner,
  ErrorBox,
} from '@mattilsynet/mt-ui'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { meldingActions } from '../../ducks/melding/actions'
import { useTypedSelector } from '../../common/custom-hooks'
import { meldingSelectors } from '../../ducks/melding/selectors'
import { INotificationObject } from '../../ducks/melding/types'
import { ApplicationsTableCellContent } from '../../components/applications-table-cell-content'

const StyledTable = styled(Table)`
  overflow-x: visible;
  overflow-y: visible;
`

const TidligereMeldinger = () => {
  const dispatch = useDispatch()
  const loadingStatus = useTypedSelector(meldingSelectors.getLoadingStatus)
  let publishedNotifications = useTypedSelector(
    meldingSelectors.getPublishedNotifications,
  )
  if (publishedNotifications) {
    publishedNotifications = publishedNotifications.sort(
      (a: INotificationObject, b: INotificationObject) =>
        dayjs(a.opprettet).isBefore(dayjs(b.opprettet))
          ? 1
          : dayjs(a.opprettet).isAfter(dayjs(b.opprettet))
            ? -1
            : 0,
    )
  }

  const fetchPublishedNotifications = useCallback(() => {
    dispatch(meldingActions.fetchPublishedNotifications())
  }, [dispatch])

  useEffect(() => {
    fetchPublishedNotifications()
  }, [fetchPublishedNotifications])

  const renderContent = () => {
    if (loadingStatus.error && !publishedNotifications) {
      return (
        <ErrorBox
          errorAction={fetchPublishedNotifications}
          errorActionText="Last innhold på nytt"
          errorText="Kunne ikke laste inn tidligere meldinger"
        />
      )
    }
    if (loadingStatus.loading) {
      return <LoadingSpinner title="Laster tidligere meldinger" />
    }
    if (!publishedNotifications || publishedNotifications.length === 0) {
      return (
        <Text size="normal" margin={[0, 0, 0, 2]}>
          Ingen tidligere meldinger
        </Text>
      )
    }

    return (
      <StyledTable
        header={[
          { display: 'Dato/tid' },
          { display: 'Melding' },
          { display: 'Omfatter' },
          { display: 'Publisert av' },
        ]}
      >
        {(publishedNotifications || []).map(
          (notification: INotificationObject) => {
            return (
              <TableRow key={notification.id}>
                <TableCell>
                  <Text>
                    {dayjs(notification.opprettet).format('DD.MM.YY')},
                  </Text>
                  <Text whiteSpace="nowrap">
                    Kl. {dayjs(notification.opprettet).format('HH:mm')}
                  </Text>
                </TableCell>
                <TableCell>{notification.body}</TableCell>
                <TableCell>
                  <ApplicationsTableCellContent
                    applications={notification.applications}
                    slakterier={notification?.slakterier}
                  />
                </TableCell>
                <TableCell>
                  <Text whiteSpace="nowrap">{notification.opprettetAv}</Text>
                </TableCell>
              </TableRow>
            )
          },
        )}
      </StyledTable>
    )
  }

  return <Column padding={[1, 0]}>{renderContent()}</Column>
}

export default TidligereMeldinger
