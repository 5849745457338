import React from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { Private } from '../../app'
import HomeRouteView from './view'
import NyMelding from '../ny-melding'
import TidligereMeldinger from '../tidligere-meldinger'

const tabBarOptions = [
  { display: 'Ny melding', value: '/' },
  { display: 'Tidligere meldinger', value: '/tidligere-meldinger' },
]

const HomeRoute = () => {
  const navigate = useNavigate()
  const goToPath = (path: string) => navigate(path)

  return (
    <HomeRouteView goToPath={goToPath} tabBarOptions={tabBarOptions}>
      <Routes>
        <Route
          path=""
          element={
            <Private>
              <NyMelding />
            </Private>
          }
        />
        <Route
          path="tidligere-meldinger"
          element={
            <Private>
              <TidligereMeldinger />
            </Private>
          }
        />
      </Routes>
    </HomeRouteView>
  )
}

export default HomeRoute
