import {
  Button,
  Column,
  ContrastBox,
  ErrorBox,
  Header,
  LoadingSpinner,
  Row,
  Text,
} from '@mattilsynet/mt-ui'
import React, { useCallback, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useDispatch } from 'react-redux'
import { ISlakteri } from '../../ducks/slakterier/types'
import { useTypedSelector } from '../../common/custom-hooks'
import { meldingSelectors } from '../../ducks/melding/selectors'
import { slakteriSelectors } from '../../ducks/slakterier/selectors'
import { slakterierActions } from '../../ducks/slakterier'
import { SelectSlakterierModal } from '../select-slakterier-modal'
import { meldingActions } from '../../ducks/melding/actions'
import { ErrorText } from '../error-text'
import { UnknownAction } from 'redux'

export const SelectSlakterierBox = ({
  error,
  showErrors,
}: {
  error?: string
  showErrors?: boolean
}) => {
  const dispatch = useDispatch()
  const [isSlakterierModalOpen, setIsSlakterierModalOpen] = useState(false)
  const selectedSlakterier: ISlakteri[] = useTypedSelector(
    meldingSelectors.getSelectedSlakterier,
  )
  const slakterierGroupedByRegion = useTypedSelector(
    slakteriSelectors.getSlakteriGroupedByRegion,
  )
  const slakterierLoadingStatus = useTypedSelector(
    slakteriSelectors.getSlakteriLoadingStatus,
  )

  const fetchSlakterier = useCallback(() => {
    dispatch(slakterierActions.fetchList() as UnknownAction)
  }, [dispatch])

  useEffect(() => {
    if (
      !slakterierLoadingStatus.loaded &&
      !slakterierLoadingStatus.loading &&
      !slakterierLoadingStatus.error
    ) {
      fetchSlakterier()
    }
  }, [fetchSlakterier, slakterierLoadingStatus])

  const alleSlakterier = slakterierGroupedByRegion.reduce(
    (acc: ISlakteri[], [, slakterier]) => [...acc, ...slakterier],
    [],
  )
  const alleSlakterierSelected = alleSlakterier.every((slakteri) =>
    selectedSlakterier.some(
      (selectedSlakteri) => selectedSlakteri.idstring === slakteri.idstring,
    ),
  )

  const isSmallScreen = useMediaQuery({ query: '(max-width: 1023px)' })

  const handleSelectSlakterier = (slakterier) => {
    dispatch(meldingActions.setSelectedSlakterier(slakterier))
  }

  if (slakterierLoadingStatus.error && !slakterierLoadingStatus.loaded) {
    return (
      <ContrastBox>
        <ErrorBox
          errorAction={fetchSlakterier}
          errorActionText="Last innhold på nytt"
          errorText="Kunne ikke hente slakterier"
        />
      </ContrastBox>
    )
  }
  if (slakterierLoadingStatus.loading || !slakterierLoadingStatus.loaded) {
    return (
      <ContrastBox>
        <LoadingSpinner title={'Laster slakterier'} />
      </ContrastBox>
    )
  }

  return (
    <div>
      <ContrastBox>
        <Column padding={0.5} spacing={selectedSlakterier.length > 0 ? 2.5 : 3}>
          <Header as="h2" size="heading3">
            Slakterier meldingen gjelder
          </Header>
          {alleSlakterierSelected ? (
            <Text size="normal">Alle slakterier</Text>
          ) : (
            <Column spacing={1} margin={[0, 0.5]}>
              {selectedSlakterier.map((slakteri) => (
                <Text key={slakteri.idstring} as="p" size="normal">
                  {slakteri.eftaNumber || ''} - {slakteri.navn}
                </Text>
              ))}
            </Column>
          )}
          <Row justify="center">
            <Button
              onClick={() => setIsSlakterierModalOpen(true)}
              secondary
              width={isSmallScreen ? '100%' : '300px'}
            >
              {selectedSlakterier.length > 0
                ? 'Legg til/fjern slakterier'
                : 'Legg til slakterier'}
            </Button>
          </Row>
        </Column>
        {isSlakterierModalOpen && (
          <SelectSlakterierModal
            isOpen={isSlakterierModalOpen}
            onCancel={() => setIsSlakterierModalOpen(false)}
            selectedSlakterier={selectedSlakterier}
            onSelectSlakterier={handleSelectSlakterier}
            slakterierGroupedByRegion={slakterierGroupedByRegion}
            alleSlakterier={alleSlakterier}
            alleSlakterierSelected={alleSlakterierSelected}
          />
        )}
      </ContrastBox>
      {showErrors && error && <ErrorText>{error}</ErrorText>}
    </div>
  )
}
