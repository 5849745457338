import React, { ReactElement } from 'react'
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom'
import { AppHeader, ThemeProvider } from '@mattilsynet/mt-ui'
import { themePicker } from './common/theme'
import { Provider, useDispatch } from 'react-redux'
import store from './reducers/store'
import { useTypedSelector } from './common/custom-hooks'
import '@mattilsynet/mt-ui/dist/assets/global.css'
import './app.css'
import { config$, oidcConfig$ } from './config'
import { uiActions } from './ducks/ui/actions'
import {
  Login,
  LoginCallback,
  Logout,
  LoggedOut,
  checkIfMeldingTilBrukerUser,
} from './components/login'
import { uiSelectors } from './ducks/ui/selectors'
import { PageLoading } from './components/page-loading'
import { PageError } from './components/page-error'
import HomeRoute from './routes/home'
import About from './routes/about'
import { OIDC } from './components/oidc'
import TopMenuElements from './containers/top-menu-elements'
import { Toast } from '@mattilsynet/mt-common'

const NotFoundRoute = () => <div>Not found</div>

config$.subscribe((config) => store.dispatch(uiActions.configLoaded(config)))
oidcConfig$.subscribe((oidcConfig) => {
  store.dispatch(uiActions.setOidcConfig(oidcConfig))
})

export const Private = ({
  children,
}: {
  children: ReactElement
}): ReactElement => {
  const user = useTypedSelector((state) => state.auth.user)
  const initializationStatus = useTypedSelector(
    uiSelectors.getInitializationStatus,
  )

  const dispatch = useDispatch()
  const errorAction = () => dispatch(uiActions.initialize())

  if (!user || user.expired) {
    return <Login />
  }

  if (!checkIfMeldingTilBrukerUser(user)) {
    return <Navigate to="/access-denied" />
  }

  if (initializationStatus.error) {
    return (
      <PageError
        errorText="Kunne ikke laste inn Melding til bruker."
        errorAction={errorAction}
        errorActionText="Prøv å laste innhold på nytt"
      />
    )
  }

  if (!initializationStatus.loaded) {
    return <PageLoading loadingText="Laster melding til bruker..." />
  }

  return children
}

const Layout = ({ env }: { env: string }) => (
  <OIDC>
    <AppHeader
      appName="Melding til bruker"
      env={env}
      right={<TopMenuElements />}
    />

    <Outlet />
    <Toast.FixedToastArea />
  </OIDC>
)

const RoutesView = () => {
  const env = useTypedSelector((state) => state.ui.environment)

  return (
    <ThemeProvider value={themePicker(env)}>
      <BrowserRouter>
        <Routes>
          <Route element={<Layout env={env} />}>
            <Route
              path="/*"
              element={
                <Private>
                  <HomeRoute />
                </Private>
              }
            />
            <Route
              path="/about"
              element={
                <Private>
                  <About />
                </Private>
              }
            />

            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/login/callback" element={<LoginCallback />} />
            <Route path="/logout/callback" element={<LoggedOut />} />

            <Route path="*" element={<NotFoundRoute />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

const App = () => {
  return (
    <Provider store={store}>
      <RoutesView />
    </Provider>
  )
}

export default App
