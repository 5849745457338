import { IUserState } from './types'

export const userActionsTypes = {
  FETCH_USER_AVDELING_FAIL: 'user/FETCH_USER_AVDELING_FAIL',
  FETCH_USER_AVDELING_OK: 'user/FETCH_USER_AVDELING_OK',
  FETCH_USER_ORGENHET_FAIL: 'user/FETCH_USER_ORGENHET_FAIL',
  FETCH_USER_ORGENHET_OK: 'user/FETCH_USER_ORGENHET_OK',
  FETCH_USER_REGION_FAIL: 'user/FETCH_USER_REGION_FAIL',
  FETCH_USER_REGION_OK: 'user/FETCH_USER_REGION_OK',
}

export const userActions = {
  fetchUserAvdelingFail: (err: string) => ({
    type: userActionsTypes.FETCH_USER_AVDELING_FAIL,
    err,
  }),

  fetchUserAvdelingOk: (data: IUserState['avdeling']) => ({
    type: userActionsTypes.FETCH_USER_AVDELING_OK,
    data,
  }),

  fetchUserOrgenhetFail: (err: string) => ({
    type: userActionsTypes.FETCH_USER_ORGENHET_FAIL,
    err,
  }),

  fetchUserOrgenhetOk: (data: IUserState['me']) => ({
    type: userActionsTypes.FETCH_USER_ORGENHET_OK,
    data,
  }),

  fetchUserRegionFail: (err: string) => ({
    type: userActionsTypes.FETCH_USER_REGION_FAIL,
    err,
  }),

  fetchUserRegionOk: (data: IUserState['region']) => ({
    type: userActionsTypes.FETCH_USER_REGION_OK,
    data,
  }),
}
