import { combineEpics, ofType, StateObservable } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators'

// actions
import { userActions, userActionsTypes } from '../actions'

// API
import { ICommonApi } from '../../../api'

// types
import { AnyAction } from 'redux'
import { IStoreState } from '../../../reducers/types'

export const getUserOrgenhet =
  (commonApi: ICommonApi) =>
  (state: IStoreState): Observable<AnyAction> =>
    of(state).pipe(
      commonApi.get('/api/v1/me/orgenhet', state),
      map((resp) => userActions.fetchUserOrgenhetOk(resp)),
      catchError((err) => of(userActions.fetchUserOrgenhetFail(err.message))),
    )

export const getUserAvdelingEpic =
  (commonApi: ICommonApi) =>
  (
    action$: Observable<AnyAction>,
    state$: StateObservable<IStoreState>,
  ): Observable<AnyAction> =>
    action$.pipe(
      ofType(userActionsTypes.FETCH_USER_ORGENHET_OK),
      withLatestFrom(state$),
      switchMap(([{ data }, state]) =>
        of(state).pipe(
          commonApi.get(
            `/api/orgenheter/parenttype/Avdeling/id/${data.id}`,
            state,
          ),
          map((resp) => userActions.fetchUserAvdelingOk(resp)),
          catchError((err) =>
            of(userActions.fetchUserAvdelingFail(err.message)),
          ),
        ),
      ),
    )

export const getUserRegionEpic =
  (commonApi: ICommonApi) =>
  (
    action$: Observable<AnyAction>,
    state$: StateObservable<IStoreState>,
  ): Observable<AnyAction> =>
    action$.pipe(
      ofType(userActionsTypes.FETCH_USER_ORGENHET_OK),
      withLatestFrom(state$),
      switchMap(([{ data }, state]) =>
        of(state).pipe(
          commonApi.get(
            `/api/orgenheter/parenttype/Region/id/${data.id}`,
            state,
          ),
          map((resp) => userActions.fetchUserRegionOk(resp)),
          catchError((err) => of(userActions.fetchUserRegionFail(err.message))),
        ),
      ),
    )

export default (commonApi: ICommonApi) =>
  combineEpics(getUserAvdelingEpic(commonApi), getUserRegionEpic(commonApi))
