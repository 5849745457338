import React, { ReactNode, useContext } from 'react'
import styled from 'styled-components'
import { IThemeColors } from '@mattilsynet/mt-ui/dist/assets/colors'
import { ThemeContext } from '@mattilsynet/mt-ui'

const StyledErrorText = styled.div`
  & {
    padding: 0.2rem 0 1.25rem 0;
    font-size: 0.8em;
    color: ${({ theme }) => theme.signalRed};
  }
`

export const ErrorText = ({ children }: { children: ReactNode }) => {
  const theme: IThemeColors = useContext(ThemeContext)
  return <StyledErrorText theme={theme}>{children}</StyledErrorText>
}
