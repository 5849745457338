import * as R from 'ramda'

// types
import { User } from 'oidc-client'
import { IStoreState } from '../../reducers/types'

const STORE_NAME = 'auth'

export const authLenses = {
  user: R.lensPath([STORE_NAME, 'user']),
  accessToken: R.lensPath([STORE_NAME, 'user', 'access_token']),
  userProfile: R.lensPath([STORE_NAME, 'user', 'profile']),
  preferredUsername: R.lensPath([
    STORE_NAME,
    'user',
    'profile',
    'preferred_username',
  ]),
  name: R.lensPath([STORE_NAME, 'user', 'profile', 'username']),
}

export const authSelectors = {
  getUser: (state: IStoreState): User => R.view(authLenses.user)(state),

  getUserProfile: (state: IStoreState): User['profile'] =>
    R.view(authLenses.userProfile)(state),

  getUserName: (state: IStoreState): string =>
    R.view(authLenses.preferredUsername)(state),
}
