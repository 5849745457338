import * as R from 'ramda'
import { createReducer } from '@mattilsynet/redux-common'
import { initialState } from './initialState'
import { userActionsTypes } from '../actions'
import { reducerLenses } from '../selectors'
import { IUserState } from '../types'

export const STORE_NAME = 'user'

const userReducer = createReducer(initialState, {
  [userActionsTypes.FETCH_USER_AVDELING_OK]: (
    state: IUserState,
    { data }: any,
  ) => R.set(reducerLenses.avdeling(), R.omit(['children'], data), state),
  [userActionsTypes.FETCH_USER_REGION_OK]: (state: IUserState, { data }: any) =>
    R.set(reducerLenses.region(), R.omit(['children'], data), state),
  [userActionsTypes.FETCH_USER_ORGENHET_OK]: (
    state: IUserState,
    { data }: any,
  ) => R.set(reducerLenses.me(), R.omit(['children'], data), state),
})

export default {
  [STORE_NAME]: userReducer,
}
