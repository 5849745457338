import { ICommonApi } from '../../api'
import { Observable, of } from 'rxjs'
import { AnyAction } from 'redux'
import { combineEpics, Epic, ofType, StateObservable } from 'redux-observable'
import { IStoreState } from '../../reducers/types'
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators'
import { meldingActions, meldingActionTypes } from './actions'

const URL = '/api/v1/publishednotifications'

export const publishNotificationEpic =
  (commonApi: ICommonApi): Epic<AnyAction, AnyAction, IStoreState> =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(meldingActionTypes.PUBLISH_NOTIFICATION),
      withLatestFrom(state$),
      switchMap(([{ notificationObject }, state]) => {
        return of(state).pipe(
          commonApi.post(URL, state, notificationObject),
          map(() => meldingActions.publishNotificationSuccess()),
          catchError((err) =>
            of(meldingActions.publishNotificationError(err.message)),
          ),
        )
      }),
    )

export const fetchPublishedNotificationsEpic =
  (commonApi: ICommonApi): Epic<AnyAction, AnyAction, IStoreState> =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(meldingActionTypes.FETCH_PUBLISHED_NOTIFICATIONS),
      withLatestFrom(state$),
      switchMap(([, state]) => {
        return of(state).pipe(
          commonApi.get(`${URL}?page.size=1000`, state),
          map((data) =>
            meldingActions.fetchPublishedNotificationsSuccess(
              data?._embedded?.publishedNotificationList,
            ),
          ),
          catchError((err) =>
            of(meldingActions.fetchPublishedNotificationsError(err.message)),
          ),
        )
      }),
    )

export default (commonApi: ICommonApi) =>
  combineEpics(
    publishNotificationEpic(commonApi),
    fetchPublishedNotificationsEpic(commonApi),
  )
