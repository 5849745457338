import { Modal } from '@mattilsynet/mt-ui'
import React, { ComponentProps } from 'react'

export const ModalWrapper = ({
  onCancel,
  isOpen,
  focusGroupElementIds,
  children,
  ...rest
}: ComponentProps<typeof Modal>) => {
  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      focusGroupElementIds={focusGroupElementIds}
      {...rest}
    >
      {children}
    </Modal>
  )
}
