import { ISlakteri } from '../slakterier/types'
import { INotificationObject } from './types'

export const meldingActionTypes = {
  SET_SELECTED_APPLIKASJONER: 'melding/SET_SELECTED_APPLIKASJONER',
  SET_SELECTED_SLAKTERIER: 'melding/SET_SELECTED_SLAKTERIER',
  SET_MELDING_TEXT: 'melding/SET_MELDING_TEXT',
  PUBLISH_NOTIFICATION: 'melding/PUBLISH_NOTIFICATION',
  PUBLISH_NOTIFICATION_SUCCESS: 'melding/PUBLISH_NOTIFICATION_SUCCESS',
  PUBLISH_NOTIFICATION_ERROR: 'melding/PUBLISH_NOTIFICATION_ERROR',
  RESET_PUBLISH_STATUS: 'melding/RESET_PUBLISH_STATUS',
  FETCH_PUBLISHED_NOTIFICATIONS: 'melding/FETCH_PUBLISHED_NOTIFICATIONS',
  FETCH_PUBLISHED_NOTIFICATIONS_SUCCESS:
    'melding/FETCH_PUBLISHED_NOTIFICATIONS_SUCCESS',
  FETCH_PUBLISHED_NOTIFICATIONS_ERROR:
    'melding/FETCH_PUBLISHED_NOTIFICATIONS_ERROR',
}

export const meldingActions = {
  setSelectedApplikasjoner: (selectedApplikasjoner: string[]) => ({
    type: meldingActionTypes.SET_SELECTED_APPLIKASJONER,
    selectedApplikasjoner,
  }),
  setSelectedSlakterier: (selectedSlakterier: ISlakteri[]) => ({
    type: meldingActionTypes.SET_SELECTED_SLAKTERIER,
    selectedSlakterier,
  }),
  setMeldingText: (meldingText: string) => ({
    type: meldingActionTypes.SET_MELDING_TEXT,
    meldingText,
  }),
  publishNotification: (notificationObject: INotificationObject) => ({
    type: meldingActionTypes.PUBLISH_NOTIFICATION,
    notificationObject,
  }),
  publishNotificationSuccess: () => ({
    type: meldingActionTypes.PUBLISH_NOTIFICATION_SUCCESS,
  }),
  publishNotificationError: (error: string) => ({
    type: meldingActionTypes.PUBLISH_NOTIFICATION_ERROR,
    error,
  }),
  resetPublishStatus: () => ({
    type: meldingActionTypes.RESET_PUBLISH_STATUS,
  }),
  fetchPublishedNotifications: () => ({
    type: meldingActionTypes.FETCH_PUBLISHED_NOTIFICATIONS,
  }),
  fetchPublishedNotificationsSuccess: (
    publishedNotifications: INotificationObject[],
  ) => ({
    type: meldingActionTypes.FETCH_PUBLISHED_NOTIFICATIONS_SUCCESS,
    publishedNotifications,
  }),
  fetchPublishedNotificationsError: (error: string) => ({
    type: meldingActionTypes.FETCH_PUBLISHED_NOTIFICATIONS_ERROR,
    error,
  }),
}
