import { IUiState } from '../types'

export const initialState: IUiState = {
  oidcUrl: '',
  oidcConfig: null,
  kcIdpHint: '',
  environment: '',
  initializationStatus: {
    loading: false,
    error: null,
    loaded: false,
  },
  title: 'Melding bruker UI',
}
