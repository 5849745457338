import * as R from 'ramda'
import { IMeldingState } from './types'
import { STORE_NAME } from './reducer'
import { IStoreState } from '../../reducers/types'
import { createSelector } from 'reselect'

export const reducerLenses = {
  meldingText: () => R.lensPath(['meldingText']),
  selectedApplikasjoner: () => R.lensPath(['selectedApplikasjoner']),
  selectedSlakterier: () => R.lensPath(['selectedSlakterier']),
  publishedNotifications: () => R.lensPath(['publishedNotifications']),
}

export const meldingLenses = R.map(
  (lens) =>
    (...args) =>
      R.compose(R.lensPath([STORE_NAME]), lens(...args)),
  reducerLenses,
)

export const meldingSelectors = {
  getMeldingText: (state: IStoreState): IMeldingState['meldingText'] =>
    R.view(meldingLenses.meldingText())(state),

  getSelectedApplikasjoner: (
    state: IStoreState,
  ): IMeldingState['selectedApplikasjoner'] =>
    R.view(meldingLenses.selectedApplikasjoner())(state),

  getSelectedSlakterier: (
    state: IStoreState,
  ): IMeldingState['selectedSlakterier'] =>
    R.view(meldingLenses.selectedSlakterier())(state),

  getSavingStatus: createSelector(
    (state: IStoreState) => state[STORE_NAME],
    (meldingState): { saving: boolean; saved: boolean; error?: string } =>
      R.pick(['saving', 'saved', 'error'], meldingState),
  ),

  getLoadingStatus: createSelector(
    (state: IStoreState) => state[STORE_NAME],
    (meldingState): { loading: boolean; error?: string } =>
      R.pick(['loading', 'error'], meldingState),
  ),

  getPublishedNotifications: (
    state: IStoreState,
  ): IMeldingState['publishedNotifications'] =>
    R.view(meldingLenses.publishedNotifications())(state),
}
