import { APPLIKASJONER } from '../../ducks/melding/types'

export interface IMeldingError {
  selectedSlakterier?: string
  meldingText?: string
  selectedApplikasjoner?: string
}

export const validateMelding = (
  meldingText,
  selectedApplikasjoner,
  selectedSlakterier,
): IMeldingError => {
  const errors: IMeldingError = {}
  if (selectedApplikasjoner.length === 0) {
    errors.selectedApplikasjoner = 'Du må velge en eller flere applikasjoner'
  }
  if (
    selectedApplikasjoner.includes(APPLIKASJONER.MAKKS) &&
    selectedSlakterier.length === 0
  ) {
    errors.selectedSlakterier = 'Slakterier mangler'
  }
  if (meldingText.length === 0) {
    errors.meldingText = 'Meldingstekst mangler'
  }
  return errors
}
