import { createReducer } from '@mattilsynet/redux-common'
import { meldingActionTypes } from '../actions'
import { initialState } from './initialState'
import { IMeldingState } from '../types'

export const STORE_NAME = 'melding'
const meldingReducer = createReducer(initialState, {
  [meldingActionTypes.RESET_PUBLISH_STATUS]: (state: IMeldingState) => ({
    ...state,
    saving: false,
    saved: false,
    error: undefined,
  }),

  [meldingActionTypes.SET_MELDING_TEXT]: (
    state: IMeldingState,
    { meldingText },
  ) => ({ ...state, meldingText }),

  [meldingActionTypes.SET_SELECTED_APPLIKASJONER]: (
    state: IMeldingState,
    {
      selectedApplikasjoner,
    }: { selectedApplikasjoner: IMeldingState['selectedApplikasjoner'] },
  ) => ({ ...state, selectedApplikasjoner }),

  [meldingActionTypes.SET_SELECTED_SLAKTERIER]: (
    state: IMeldingState,
    {
      selectedSlakterier,
    }: { selectedSlakterier: IMeldingState['selectedSlakterier'] },
  ) => ({ ...state, selectedSlakterier }),

  [meldingActionTypes.PUBLISH_NOTIFICATION]: (state: IMeldingState) => ({
    ...state,
    saving: true,
  }),

  [meldingActionTypes.PUBLISH_NOTIFICATION_SUCCESS]: () => ({
    ...initialState,
    saving: false,
    saved: true,
    error: undefined,
  }),

  [meldingActionTypes.PUBLISH_NOTIFICATION_ERROR]: (
    state: IMeldingState,
    { error }: { error: string },
  ) => ({ ...state, saving: false, saved: false, error }),

  [meldingActionTypes.FETCH_PUBLISHED_NOTIFICATIONS]: (
    state: IMeldingState,
  ) => ({ ...state, loading: true }),

  [meldingActionTypes.FETCH_PUBLISHED_NOTIFICATIONS_SUCCESS]: (
    state: IMeldingState,
    {
      publishedNotifications,
    }: { publishedNotifications: IMeldingState['publishedNotifications'] },
  ) => ({ ...state, loading: false, publishedNotifications }),

  [meldingActionTypes.FETCH_PUBLISHED_NOTIFICATIONS_ERROR]: (
    state: IMeldingState,
    { error }: { error: string },
  ) => ({ ...state, loading: false, error }),
})

export default {
  [STORE_NAME]: meldingReducer,
}
