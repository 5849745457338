import React from 'react'

import { UserMenu as UserMenuTkUi, TextLink, Row } from '@mattilsynet/mt-ui'
import './style.css'

import { User } from 'oidc-client'
import { IUserState } from '../../ducks/user/types'
import { FocusEvent, RefObject } from 'react'

export interface IUserMenuProps {
  isOpen: boolean
  toggleMenu: () => void
  goToLogout: () => void
  user: User['profile'] | IUserState['me']
  userAvdeling: IUserState['avdeling']
  userMenuRef: RefObject<HTMLDivElement>
  onCloseMenuOnBlur: (event: FocusEvent<HTMLDivElement>) => void
  goToAbout: () => void
}

export const UserMenuView: React.FC<IUserMenuProps> = ({
  goToAbout,
  goToLogout,
  isOpen,
  onCloseMenuOnBlur,
  toggleMenu,
  user,
  userAvdeling,
  userMenuRef,
}: any) => {
  return (
    <Row
      className="user-menu-container"
      onBlur={onCloseMenuOnBlur}
      ref={userMenuRef}
    >
      <UserMenuTkUi
        isOpen={isOpen}
        onClick={toggleMenu}
        name={user.name || user.username}
        title={user.title || ''}
        division={userAvdeling.name || ''}
      >
        <div className="link-group">
          <TextLink onClick={goToAbout}>Om applikasjonen</TextLink>
        </div>
        <TextLink onClick={goToLogout}>Logg ut</TextLink>
      </UserMenuTkUi>
    </Row>
  )
}
