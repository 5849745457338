import React, { useEffect } from 'react'
import { LoginCallbackView, LoginView, LogoutView, LoggedOutView } from './view'
import { useTypedSelector } from '../../common/custom-hooks'
import { authSelectors } from '../../ducks/auth'
import { jwtDecode } from 'jwt-decode'
import { User as IAuthUser } from 'oidc-client'
import { useUserManager } from '../oidc'
import { useNavigate } from 'react-router-dom'

const excludedPaths =
  /^\/login|^\/logout|^\/setup|^\/access-denied|^\/forbered-new-tilsyn/

const pathIfValid = (path: string | null): string =>
  !path || excludedPaths.test(path) ? '/' : path

export const checkIfMeldingTilBrukerUser = (authUser: IAuthUser): boolean => {
  try {
    const accessTokenData = jwtDecode<Record<string, any>>(
      authUser.access_token,
    )

    return accessTokenData.realm_access.roles.includes(
      'melding-til-bruker-user',
    )
  } catch {
    return false
  }
}

export const LoginCallback = () => {
  const navigate = useNavigate()
  const authUser = useTypedSelector(authSelectors.getUser)

  const fail = () => navigate('/')
  const success = (path: string) => () => {
    navigate(checkIfMeldingTilBrukerUser(authUser) ? path : '/access-denied')
  }

  const path = pathIfValid(new URLSearchParams(location.search).get('path'))

  return <LoginCallbackView fail={fail} path={path} success={success} />
}

export const Login = LoginView

export const Logout = () => {
  const userManager = useUserManager()

  useEffect(() => {
    userManager.removeUser()
    userManager.signoutRedirect()
  }, [userManager])

  return <LogoutView />
}

export const LoggedOut = LoggedOutView
