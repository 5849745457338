import {
  createReducer,
  fetchActions,
  fetchActionTypes,
  fetchWithIndexReducer,
  initialListState,
} from '@mattilsynet/redux-common'

export const STORE_NAME = 'slakterier'

export const slakterierActionTypes = fetchActionTypes(STORE_NAME)

export const slakterierActions = fetchActions(slakterierActionTypes)

export const slakterierReducer = {
  [STORE_NAME]: createReducer(
    initialListState,
    fetchWithIndexReducer(slakterierActionTypes, 'idstring'),
  ),
}
