import React from 'react'
import { CallbackComponent } from 'redux-oidc'

import { ILoginCallbackViewProps } from './types'
import { Button } from '@mattilsynet/mt-ui'
import './style.css'
import { PageLoading } from '../page-loading'
import { useUserManager } from '../oidc'
import { useTypedSelector } from '../../common/custom-hooks'

export const LoginView = () => {
  const isLoadingUser = useTypedSelector((state) => state.auth.isLoadingUser)
  const userManager = useUserManager()
  !isLoadingUser && userManager.signinRedirect()

  return <PageLoading loadingText="Logger inn" />
}

export const LoggedOutView = () => {
  const userManager = useUserManager()

  return (
    <div id="login">
      <div className="content">
        <div className="text">Du er ikke logget inn.</div>
        <Button onClick={() => userManager.signinRedirect()}>Logg inn</Button>
      </div>
    </div>
  )
}

export const LoginCallbackView: React.FC<ILoginCallbackViewProps> = ({
  success,
  fail,
  path,
}: any) => {
  const userManager = useUserManager()

  return (
    // @ts-ignore
    <CallbackComponent
      successCallback={success(path)}
      errorCallback={fail}
      userManager={userManager}
    >
      <PageLoading loadingText="Laster melding bruker..." />
    </CallbackComponent>
  )
}

export const LogoutView = () => {
  return <PageLoading loadingText="Logger ut" />
}
