import React, { useEffect, useState } from 'react'
import { AboutView } from './view'
import { useNavigate } from 'react-router-dom'

const About = () => {
  const [apiStatus, setApiStatus] = useState({ checked: false, ok: false })
  const navigate = useNavigate()

  const goBackOnclick = () => navigate(-1)

  useEffect(() => {
    fetch('/api')
      .then((cb) => {
        if (cb.ok) {
          setApiStatus({
            checked: true,
            ok: true,
          })
        }
      })
      .catch(() => {
        setApiStatus({
          checked: true,
          ok: false,
        })
      })
  }, [])

  return <AboutView apiStatus={apiStatus} goBack={goBackOnclick} />
}

export default About
