import React, { ReactNode } from 'react'
import { Column, Grid, GridItem, TabBar } from '@mattilsynet/mt-ui'
import { useLocation } from 'react-router-dom'

interface IViewProps {
  children: ReactNode
  goToPath: (path: string) => void
  tabBarOptions: { display: string; value: string }[]
}

const HomeRouteView: React.FC<IViewProps> = ({
  children,
  goToPath,
  tabBarOptions,
}: IViewProps) => {
  const location = useLocation()

  return (
    <Grid>
      <GridItem xl={[3, -3]} lg={[3, -3]} md={[1, -1]} sm={[1, -1]}>
        <Column spacing={3}>
          <TabBar
            options={tabBarOptions}
            selectedValue={location.pathname}
            onClick={goToPath}
            size="large"
            forceShowAsDropdown={false}
          />
          <GridItem backgroundColor="#FFF">{children}</GridItem>
        </Column>
      </GridItem>
    </Grid>
  )
}

export default HomeRouteView
