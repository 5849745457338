// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', Areal, sans-serif;
  font-size: 16px;
  background-color: #f2f2f2;
  color: #222222;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

.ModalWrapper .modal-child {
  max-width: 50em;
}
`, "",{"version":3,"sources":["webpack://./src/app.css"],"names":[],"mappings":"AAEA;EACE,SAAS;EACT,UAAU;EACV,iDAAiD;EACjD,eAAe;EACf,yBAAyB;EACzB,cAAc;AAChB;;AAEA;;;;;;EAME,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700');\n\nbody {\n  margin: 0;\n  padding: 0;\n  font-family: 'Source Sans Pro', Areal, sans-serif;\n  font-size: 16px;\n  background-color: #f2f2f2;\n  color: #222222;\n}\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  font-weight: bold;\n}\n\n.ModalWrapper .modal-child {\n  max-width: 50em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
