import { checkIfMeldingTilBrukerUser } from '../../../components/login'
import { uiActions, uiActionTypes } from '../actions'
import { combineEpics, ofType, StateObservable } from 'redux-observable'
import {
  catchError,
  filter,
  first,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators'
import { concat, Observable, of } from 'rxjs'
import { USER_FOUND } from 'redux-oidc'

// Types
import { AnyAction } from 'redux'
import { IStoreState } from '../../../reducers/types'
import { ICommonApi } from '../../../api'
import { getUserOrgenhet } from '../../user/epic'

export const userFoundAccessDeniedEpic = (
  action$: Observable<AnyAction>,
): Observable<AnyAction> =>
  action$.pipe(
    ofType(USER_FOUND),
    filter(({ payload }) => !checkIfMeldingTilBrukerUser(payload)),
    mergeMap(() => concat(of(uiActions.accessDenied()))),
  )
export const initialLoadingWhenUserFoundEpic = (
  action$: Observable<AnyAction>,
): Observable<AnyAction> =>
  action$.pipe(
    ofType(USER_FOUND),
    first(({ payload }) => checkIfMeldingTilBrukerUser(payload)),
    map(() => uiActions.initialize()),
  )
export const initialLoadingEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(uiActionTypes.INITIALIZE),
      withLatestFrom(state$),
      mergeMap(([, state]) =>
        concat(
          getUserOrgenhet(commonApi)(state),
          of(uiActions.initializeSuccess()),
        ).pipe(
          catchError((err) => of(uiActions.initializeFailed(err.message))),
        ),
      ),
    )
export default (commonApi: ICommonApi) =>
  combineEpics(
    initialLoadingEpic(commonApi),
    initialLoadingWhenUserFoundEpic,
    userFoundAccessDeniedEpic,
  )
