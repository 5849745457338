import React, { useCallback, useEffect, useState } from 'react'
import {
  Grid,
  GridItem,
  Header,
  SelectList,
  TextArea,
  Row,
  Column,
  Button,
} from '@mattilsynet/mt-ui'
import { IToast, toast } from '@mattilsynet/mt-common'
import { useMediaQuery } from 'react-responsive'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { useTypedSelector } from '../../common/custom-hooks'
import { meldingSelectors } from '../../ducks/melding/selectors'
import { meldingActions } from '../../ducks/melding/actions'
import { SelectSlakterierBox } from '../../components/select-slakterier-box'
import { ISlakteri } from '../../ducks/slakterier/types'
import { ConfirmSendModal } from '../../components/confirm-send-modal'
import { IMeldingError, validateMelding } from './validateMelding'
import {
  APPLIKASJONER,
  applikasjonerList,
  ISlakteriPayload,
} from '../../ducks/melding/types'
import { authSelectors } from '../../ducks/auth'
import { ErrorText } from '../../components/error-text'
import { slakteriSelectors } from '../../ducks/slakterier/selectors'

export const slakterierAndMeldingMissingToast = (): IToast => ({
  text: 'Du må legge til både hvilke slakterier meldingen gjelder og meldingstekst, før du kan publisere meldingen',
  timeoutSeconds: 10,
  dismissible: true,
  type: 'DANGER',
})
export const applikasjonerAndMeldingMissingToast = (): IToast => ({
  text: 'Du må legge til både hvilke applikasjoner meldingen gjelder og meldingstekst, før du kan publisere meldingen',
  timeoutSeconds: 10,
  dismissible: true,
  type: 'DANGER',
})
export const slakterierMissingToast = (): IToast => ({
  text: 'Du må legge til hvilke slakterier meldingen gjelder, før du kan publisere meldingen',
  timeoutSeconds: 10,
  dismissible: true,
  type: 'DANGER',
})
export const applikasjonerMissingToast = (): IToast => ({
  text: 'Du må legge til hvilke applikasjoner meldingen gjelder, før du kan publisere meldingen',
  timeoutSeconds: 10,
  dismissible: true,
  type: 'DANGER',
})
export const meldingMissingToast = (): IToast => ({
  text: 'Du må legge til meldingstekst, før du kan publisere meldingen',
  timeoutSeconds: 10,
  dismissible: true,
  type: 'DANGER',
})

const NyMelding = () => {
  const dispatch = useDispatch()
  const meldingText: string = useTypedSelector(meldingSelectors.getMeldingText)
  const selectedApplikasjoner: APPLIKASJONER[] = useTypedSelector(
    meldingSelectors.getSelectedApplikasjoner,
  )
  const selectedSlakterier: ISlakteri[] = useTypedSelector(
    meldingSelectors.getSelectedSlakterier,
  )
  const slakterierGroupedByRegion = useTypedSelector(
    slakteriSelectors.getSlakteriGroupedByRegion,
  )
  const publishStatus = useTypedSelector(meldingSelectors.getSavingStatus)
  const userName = useTypedSelector(authSelectors.getUserProfile)?.name
  const [errors, setErrors] = useState<IMeldingError>({})
  const [showErrors, setShowErrors] = useState(false)
  const [isConfirmSendDialogOpen, setIsConfirmSendDialogOpen] = useState(false)
  const smallDevice = useMediaQuery({ maxWidth: '767px' })

  const validate = useCallback(() => {
    if (selectedApplikasjoner.length === 0) {
      setShowErrors(false)
    }

    setErrors(
      validateMelding(meldingText, selectedApplikasjoner, selectedSlakterier),
    )
  }, [setErrors, meldingText, selectedApplikasjoner, selectedSlakterier])

  const handleSelectApplikasjon = (value) => {
    if (
      value === APPLIKASJONER.MAKKS &&
      selectedApplikasjoner.includes(APPLIKASJONER.MAKKS)
    ) {
      dispatch(meldingActions.setSelectedSlakterier([]))
    }
    dispatch(
      meldingActions.setSelectedApplikasjoner(
        selectedApplikasjoner.includes(value)
          ? selectedApplikasjoner.filter((app) => app !== value)
          : [...selectedApplikasjoner, value],
      ),
    )
  }

  const handleMeldingChange = (e) => {
    dispatch(meldingActions.setMeldingText(e.target.value))
  }

  useEffect(() => {
    validate()
  }, [validate])

  const onPublishMessage = () => {
    setShowErrors(false)
    const alleSlakterier = slakterierGroupedByRegion.reduce(
      (acc: ISlakteri[], [, slakterier]) => [...acc, ...slakterier],
      [],
    )
    const alleSlakterierSelected = alleSlakterier.every((slakteri) =>
      selectedSlakterier.some(
        (selectedSlakteri) => selectedSlakteri.idstring === slakteri.idstring,
      ),
    )

    const slakterier: ISlakteriPayload[] = alleSlakterierSelected
      ? []
      : selectedSlakterier
          .filter((slakteri) => !!slakteri.eftaNumber)
          .map((slakteri) => ({
            eftanummer: slakteri.eftaNumber,
            navn: slakteri.navn!,
          }))

    dispatch(
      meldingActions.publishNotification({
        applications: selectedApplikasjoner,
        slakterier,
        body: meldingText,
        opprettet: dayjs().toISOString(),
        opprettetAv: userName || 'Ukjent',
        title: 'Ny melding',
        topic: 'melding-til-bruker',
      }),
    )
  }

  const onSubmit = () => {
    setShowErrors(true)

    if (Object.keys(errors).length === 0) {
      setIsConfirmSendDialogOpen(true)
    } else {
      if (errors.meldingText && errors.selectedApplikasjoner) {
        return dispatch(
          toast.actions.showToast(applikasjonerAndMeldingMissingToast()),
        )
      }
      if (errors.meldingText && errors.selectedSlakterier) {
        return dispatch(
          toast.actions.showToast(slakterierAndMeldingMissingToast()),
        )
      }
      if (errors.selectedApplikasjoner) {
        return dispatch(toast.actions.showToast(applikasjonerMissingToast()))
      }
      if (errors.selectedSlakterier) {
        return dispatch(toast.actions.showToast(slakterierMissingToast()))
      }
      if (errors.meldingText) {
        return dispatch(toast.actions.showToast(meldingMissingToast()))
      }
    }
  }

  return (
    <Grid>
      <GridItem
        xl={[2, -2]}
        lg={[1, -1]}
        md={[1, -1]}
        sm={[1, -1]}
        padding={[0.6, smallDevice ? 1 : 3]}
      >
        <Column spacing={3}>
          <Header as="h2" size="heading2">
            Meldingen omfatter
          </Header>
          <div>
            <SelectList
              dataList={applikasjonerList}
              onClick={handleSelectApplikasjon}
              selected={selectedApplikasjoner}
            />
            {showErrors && errors.selectedApplikasjoner && (
              <ErrorText>{errors.selectedApplikasjoner}</ErrorText>
            )}
          </div>

          {selectedApplikasjoner.includes(APPLIKASJONER.MAKKS) && (
            <SelectSlakterierBox
              showErrors={showErrors}
              error={errors.selectedSlakterier}
            />
          )}

          <TextArea
            label="Melding"
            placeholder="Meldingstekst"
            onChange={handleMeldingChange}
            value={meldingText}
            errorText={showErrors ? errors.meldingText : undefined}
            danger={showErrors && !!errors.meldingText}
            autoGrow
          />

          <Row justify="center">
            <Button
              onClick={onSubmit}
              large
              fill
              width={smallDevice ? '100%' : '300px'}
            >
              Publiser
            </Button>
          </Row>
        </Column>
      </GridItem>
      {isConfirmSendDialogOpen && (
        <ConfirmSendModal
          isOpen={isConfirmSendDialogOpen}
          onCancel={() => {
            setIsConfirmSendDialogOpen(false)
            dispatch(meldingActions.resetPublishStatus())
          }}
          onOk={onPublishMessage}
          publishStatus={publishStatus}
        />
      )}
    </Grid>
  )
}

export default NyMelding
