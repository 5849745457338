import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
  AnyAction,
} from 'redux'

// Redux
import { reducer as authReducer } from 'redux-oidc'
import { createEpicMiddleware } from 'redux-observable'

// Types
import { IStoreState } from './types'

// Epics
import epics from '../epics'

// Own reducers
import meldingReducer from '../ducks/melding/reducer'
import { slakterierReducer } from '../ducks/slakterier/'
import uiReducer from '../ducks/ui/reducer'
import userReducer from '../ducks/user/reducer'
// import { userSettingsReducer } from '../ducks/user-settings/store'

// External
import { toast, notifications } from '@mattilsynet/mt-common'

const epicMiddleware = createEpicMiddleware<
  AnyAction,
  AnyAction,
  IStoreState,
  void
>()

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const enhancers = composeEnhancers(applyMiddleware(epicMiddleware))

const reducers = {
  auth: authReducer,
  ...meldingReducer,
  ...notifications.reducer,
  ...slakterierReducer,
  ...toast.reducer,
  ...uiReducer,
  ...userReducer,
}

const rootReducer = combineReducers(reducers)

const store: Store<IStoreState> = createStore(rootReducer, enhancers)
epicMiddleware.run(epics)

export default store
