import React, { FocusEvent, RefObject, useRef, useState } from 'react'
import { useTypedSelector } from '../../common/custom-hooks'

// selectors
import { userSelectors } from '../../ducks/user/selectors'
import { authSelectors } from '../../ducks/auth'

//  view
import { UserMenuView } from './view'
import { useNavigate } from 'react-router-dom'

const UserMenu = () => {
  const authUser = useTypedSelector(authSelectors.getUserProfile)
  const userAvdeling = useTypedSelector(userSelectors.getAvdeling)
  const user = useTypedSelector(userSelectors.getMe)
  const navigate = useNavigate()

  const [isOpen, toggleUserMenuOpen] = useState(false)
  const userMenuRef: RefObject<HTMLDivElement> = useRef(null)

  const goToPathAndCloseMenu = (path: string) => () => {
    toggleUserMenuOpen(false)
    navigate(path)
  }

  const onCloseMenuOnBlur = (event: FocusEvent) => {
    if (
      !event.relatedTarget ||
      (userMenuRef.current &&
        !userMenuRef.current.contains(event.relatedTarget as HTMLDivElement))
    ) {
      return toggleUserMenuOpen(false)
    }
  }

  if (!authUser) {
    return null
  }

  return (
    <UserMenuView
      isOpen={isOpen}
      toggleMenu={() => toggleUserMenuOpen(!isOpen)}
      goToLogout={goToPathAndCloseMenu('/logout')}
      user={user.name ? user : authUser}
      userAvdeling={userAvdeling}
      userMenuRef={userMenuRef}
      onCloseMenuOnBlur={onCloseMenuOnBlur}
      goToAbout={goToPathAndCloseMenu('/about')}
    />
  )
}

export default UserMenu
