import React, { useContext } from 'react'
import { ErrorBox, Grid, GridItem, Row, ThemeContext } from '@mattilsynet/mt-ui'

interface IPageErrorProps {
  errorAction: () => void
  errorActionText: string
  errorText: string
}

export const PageError = ({
  errorAction,
  errorActionText,
  errorText,
}: IPageErrorProps) => {
  const theme = useContext(ThemeContext)
  return (
    <Grid>
      <GridItem backgroundColor={theme.white}>
        <Row minHeight="91vh" center>
          <ErrorBox
            errorText={errorText}
            errorAction={errorAction}
            errorActionText={errorActionText}
          />
        </Row>
      </GridItem>
    </Grid>
  )
}
