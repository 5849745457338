import React, { useContext } from 'react'
import {
  Header,
  Text,
  Column,
  Row,
  Button,
  ThemeContext,
  CrossDeleteIcon,
  Checkmark,
  LoadingSpinner,
} from '@mattilsynet/mt-ui'
import { IThemeColors } from '@mattilsynet/mt-ui/dist/assets/colors'
import styled from 'styled-components'
import { ModalWrapper } from '../modal-wrapper'

interface IConfirmSendModalProps {
  isOpen: boolean
  onCancel: () => void
  onOk: () => void
  publishStatus: { saving: boolean; saved: boolean; error?: string }
}

const StyledRow = styled(Row)`
  & .icon {
    min-width: 1.5em;
    max-width: 1.5em;
    min-height: 1.5em;
    max-height: 1.5em;
    padding-right: 0.6875em;
  }
`

export const ConfirmSendModal = ({
  isOpen,
  onCancel,
  onOk,
  publishStatus,
}: IConfirmSendModalProps) => {
  const theme: IThemeColors = useContext(ThemeContext)

  const renderContent = () => {
    if (publishStatus.error) {
      return (
        <StyledRow>
          <CrossDeleteIcon color={theme.signalRed} className="icon" />
          <Text size="normal" weight="bold">
            En feil oppstod
          </Text>
        </StyledRow>
      )
    }
    if (publishStatus.saved) {
      return (
        <StyledRow>
          <Checkmark color={theme.signalGreen} className="icon" />
          <Text size="normal" weight="bold">
            Meldingen ble publisert
          </Text>
        </StyledRow>
      )
    }
    if (publishStatus.saving) {
      return <LoadingSpinner title="Publiserer melding" small />
    }
    return (
      <>
        <Column spacing={2} align="center">
          <Text as="p" size="normal">
            Du er i ferd med å publisere en melding for brukere.
          </Text>
          <Header as="h1" size="normal">
            Er du sikker på at du vil publisere meldingen?
          </Header>
        </Column>
        <Column spacing={1}>
          <Button width="18.75em" onClick={onOk}>
            Ja, publiser meldingen
          </Button>
          <Button width="18.75em" onClick={onCancel} secondary>
            Avbryt
          </Button>
        </Column>
      </>
    )
  }

  return (
    <ModalWrapper onCancel={onCancel} isOpen={isOpen} onOk={onOk}>
      <Column spacing={2.5} align="center" paddingVertical={5}>
        {renderContent()}
      </Column>
    </ModalWrapper>
  )
}
