import { combineEpics } from 'redux-observable'
import { apm } from '@elastic/apm-rum'
import { catchError } from 'rxjs/operators'

import uiEpic from '../ducks/ui/epic'
import userEpic from '../ducks/user/epic'
// import userSettingsEpic from '../ducks/user-settings/epic'
import { commonApi } from '../api'
import { notifications } from '@mattilsynet/mt-common'
import slakterierEpic from '../ducks/slakterier/epic'
import meldingEpic from '../ducks/melding/epic'

const authTokenPath = ['auth', 'user', 'access_token']

const rootEpic = (action$, store$, dependencies) =>
  combineEpics(
    // userSettingsEpic,
    meldingEpic(commonApi),
    notifications.epic(authTokenPath, '/api/notification-api'),
    slakterierEpic(commonApi),
    uiEpic(commonApi),
    userEpic(commonApi),
  )(action$, store$, dependencies).pipe(
    catchError((err, source) => {
      // eslint-disable-next-line no-console
      console.error('catched', err)
      apm.captureError(err)
      return source
    }),
  )

export default rootEpic
