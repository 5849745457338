import React, { ReactNode, useContext } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import classNames from 'classnames'
import { ThemeContext } from '@mattilsynet/mt-ui'

const StyledPopover = styled.div`
  &.target {
    position: relative;
  }
  &.target.smallDevice {
    position: absolute;
    width: calc(100% - 2em);
  }

  & .popover {
    position: absolute;
    left: 0;
    top: 1.5em;
    width: 18em;
    padding: 1rem;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.gray4};
  }
  & .popover.smallDevice {
    width: calc(100% - 2em);
    left: -1.2em;
  }
`

export const Popover = ({ children }: { children: ReactNode }) => {
  const theme = useContext(ThemeContext)
  const smallDevice = useMediaQuery({ query: '(max-width: 500px)' })
  return (
    <StyledPopover
      className={classNames('target', { smallDevice })}
      theme={theme}
    >
      <div className={classNames('popover', { smallDevice })}>{children}</div>
    </StyledPopover>
  )
}
