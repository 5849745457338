import { ISlakteri } from '../slakterier/types'

export enum APPLIKASJONER {
  MAKKS = 'MAKKS',
  TILSYNSKVITTERING = 'Tilsynskvittering',
  FOTO = 'Fotoløsning',
  MELDING_TIL_LOKALT_MATTILSYN = 'Melding til lokalt Mattilsyn',
}

export const applikasjonerList = [
  { label: 'MAKKS', value: APPLIKASJONER.MAKKS },
  { label: 'Tilsynskvittering', value: APPLIKASJONER.TILSYNSKVITTERING },
  { label: 'Fotoløsning', value: APPLIKASJONER.FOTO },
  {
    label: 'Melding til lokalt Mattilsyn',
    value: APPLIKASJONER.MELDING_TIL_LOKALT_MATTILSYN,
  },
]

export interface IMeldingState {
  meldingText: string
  selectedApplikasjoner: APPLIKASJONER[]
  selectedSlakterier: ISlakteri[]
  saving: boolean
  saved: boolean
  error?: string
  publishedNotifications?: INotificationObject[]
  loading: boolean
}

export interface ISlakteriPayload {
  eftanummer: string
  navn: string
}

export interface INotificationObject {
  id?: number
  applications: APPLIKASJONER[]
  slakterier: ISlakteriPayload[]
  body: string
  opprettet: string
  opprettetAv: string
  title: string
  topic: string
}
