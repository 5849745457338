import * as R from 'ramda'

export const withEftaNumber = R.map((slakteri) => {
  return {
    ...slakteri,
    eftaNumber: R.pipe(
      R.prop('identiteter'),
      R.defaultTo([]),
      R.indexBy(R.path(['identitetstype', 'id'])),
      R.path(['IDENTITETSTYPE$EFTANUMMER', 'verdi']),
    )(slakteri),
  }
})
export const withSortedByEftaNumber = R.sortBy(
  R.pipe(R.prop('eftaNumber'), Number, R.defaultTo(Infinity)),
)

export const formatSlakteriRespons = R.pipe(
  withEftaNumber,
  withSortedByEftaNumber,
)
