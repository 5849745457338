import { IMeldingState } from '../types'

export const initialState: IMeldingState = {
  meldingText: '',
  selectedApplikasjoner: [],
  selectedSlakterier: [],
  saving: false,
  saved: false,
  error: undefined,
  publishedNotifications: undefined,
  loading: false,
}
