import { useTypedSelector } from '../../common/custom-hooks'
import React, { ReactNode, useEffect } from 'react'
import { getClientSettings } from '../../common/userManager'
import { loadUser, OidcProvider } from 'redux-oidc'
import store from '../../reducers/store'
import { UserManager } from 'oidc-client'
import { useLocation } from 'react-router-dom'

const userManagerContext = React.createContext<UserManager | null>(null)
const UserManagerProvider = userManagerContext.Provider
export const useUserManager = () => React.useContext(userManagerContext)!

export const OIDC = ({ children }: { children: ReactNode }) => {
  const [userManager, setUserManager] = React.useState<UserManager | null>(null)
  const oidcUrl = useTypedSelector((state) => state.ui.oidcUrl)
  const oidcConfig = useTypedSelector((state) => state.ui.oidcConfig)
  const kcIdpHint = useTypedSelector((state) => state.ui.kcIdpHint)
  const { pathname, search } = useLocation()

  useEffect(() => {
    if (!userManager && !!oidcUrl && !!oidcConfig) {
      const userManager = getClientSettings(
        oidcUrl,
        pathname,
        oidcConfig.links,
        oidcConfig.certs,
        kcIdpHint,
      )

      loadUser(store, userManager)
      setUserManager(userManager)
    }
  }, [kcIdpHint, oidcConfig, oidcUrl, pathname, search, userManager])

  if (!userManager) return null

  return (
    /* @ts-ignore */
    <OidcProvider store={store} userManager={userManager}>
      <UserManagerProvider value={userManager}>{children}</UserManagerProvider>
    </OidcProvider>
  )
}
