import { IConfigInterface, IOidcConfig } from '../../config.d'

export const uiActionTypes = {
  ACCESS_DENIED: 'ui/ACCESS_DENIED',
  CONFIG_LOADED: 'ui/CONFIG_LOADED',
  INITIALIZE: 'ui/INITIALIZE',
  INITIALIZE_FAILED: 'ui/INITIALIZE_FAILED',
  INITIALIZE_SUCCESS: 'ui/INITIALIZE_SUCCESS',
  SET_OIDC_CONFIG: 'ui/SET_OIDC_CONFIG',
  SET_PAGE_TITLE: 'ui/SET_PAGE_TITLE',
}

export const uiActions = {
  accessDenied: () => ({
    type: uiActionTypes.ACCESS_DENIED,
  }),

  configLoaded: (config: IConfigInterface) => ({
    type: uiActionTypes.CONFIG_LOADED,
    payload: config,
  }),

  setPageTitle: (title: string) => ({
    type: uiActionTypes.SET_PAGE_TITLE,
    title: title,
  }),

  setOidcConfig: (oidcConfig: IOidcConfig) => ({
    type: uiActionTypes.SET_OIDC_CONFIG,
    oidcConfig: oidcConfig,
  }),

  initialize: () => ({ type: uiActionTypes.INITIALIZE }),
  initializeSuccess: () => ({ type: uiActionTypes.INITIALIZE_SUCCESS }),
  initializeFailed: (error) => ({
    type: uiActionTypes.INITIALIZE_FAILED,
    error,
  }),
}
