import React, { useEffect, useRef, useState } from 'react'
import {
  APPLIKASJONER,
  applikasjonerList,
  ISlakteriPayload,
} from '../../ducks/melding/types'
import { IconButton, RoundIndicator, Row, Text } from '@mattilsynet/mt-ui'
import { Popover } from '../popover'

const useOnOutsideClick = (ref, onClickOutside) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, onClickOutside])
}

const SlakterierIndicatorButton = ({
  isPopoverOpen,
  setIsPopoverOpen,
}: {
  isPopoverOpen: boolean
  setIsPopoverOpen: (isOpen) => void
}) => {
  const ref = useRef(null)
  useOnOutsideClick(ref, () => {
    setIsPopoverOpen(false)
  })

  return (
    <div ref={ref}>
      <IconButton
        icon={<RoundIndicator>i</RoundIndicator>}
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        ariaLabel="Åpner liste av omfattede slakterier"
      />
    </div>
  )
}

interface IProps {
  applications: APPLIKASJONER[]
  slakterier: ISlakteriPayload[]
}

export const ApplicationsTableCellContent = ({
  applications,
  slakterier,
}: IProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  return (
    <div>
      {applications.map((application: APPLIKASJONER) => {
        const appObject = applikasjonerList.find(
          (app) => app.value === application,
        )
        return (
          <Row key={appObject?.value} align="center">
            <Text size="normal">{appObject?.label}</Text>
            {appObject?.value === APPLIKASJONER.MAKKS && (
              <SlakterierIndicatorButton
                isPopoverOpen={isPopoverOpen}
                setIsPopoverOpen={(isOpen) => setIsPopoverOpen(isOpen)}
              />
            )}
            {isPopoverOpen && appObject?.value === APPLIKASJONER.MAKKS && (
              <Popover>
                <Text as="p" weight="bold" margin={[0, 0, 2, 0]}>
                  Meldingen gjelder følgende slakterier:
                </Text>
                {slakterier.length === 0 ? (
                  <Text>Alle slakterier</Text>
                ) : (
                  <div>
                    {slakterier.map((slakteri) => {
                      return (
                        <Text key={slakteri.eftanummer} margin={[0, 0, 2, 0]}>
                          {slakteri.eftanummer} - {slakteri.navn}
                        </Text>
                      )
                    })}
                  </div>
                )}
              </Popover>
            )}
          </Row>
        )
      })}
    </div>
  )
}
