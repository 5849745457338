import React from 'react'
import {
  TextLink,
  GridItem,
  Row,
  Grid,
  Column,
  RowItem,
  ShadowBox,
  Header,
  Text,
} from '@mattilsynet/mt-ui'
import buildInfo from '../../../package.json'
import { StatusIndicator } from '../../components/status-indicator'

interface IAboutViewProps {
  apiStatus: {
    checked: boolean
    ok: boolean
  }
  goBack: () => void
}

export const AboutView = ({ apiStatus, goBack }: IAboutViewProps) => (
  <Grid id="about">
    <GridItem xl={[1, -1]} lg={[1, -1]} md={[1, -1]} sm={[1, -1]}>
      <Row>
        <TextLink leftArrow onClick={goBack}>
          Tilbake
        </TextLink>
      </Row>
    </GridItem>
    <GridItem
      xl={[3, -3]}
      lg={[3, -3]}
      md={[1, -1]}
      sm={[1, -1]}
      backgroundColor="#fff"
    >
      <ShadowBox>
        <Column spacing={1} padding={2.5}>
          <Header as="h1" size="heading1">
            Om applikasjonen
          </Header>
          <Column spacing={1}>
            <Header as="h2" size="heading3">
              Melding til bruker
            </Header>
            <Text size="normal">
              Dette er et administrasjonsverktøy hvor du kan sende
              notifikasjonsmeldinger til brukerne av utvalgte applikasjoner.
              Dette kan være nyttig når du ønsker å informere dem om
              driftsproblemer, planlagt driftsarbeid, nylig utviklet
              funksjonalitet eller andre hendelser det er nyttig for dem å vite
              om. Meldingene vil publiseres som notifikasjoner i bjelleikonet
              som finnes oppe til høyre i de nye applikasjonene.
            </Text>
          </Column>
          <Column spacing={1}>
            <Header as="h2" size="heading3">
              Brukerstøtte
            </Header>
            <div>
              <Text as="span" size="normal" margin={[0, 0.5, 0, 0]}>
                Hvis du har behov for hjelp kan du opprette
              </Text>
              <TextLink
                href="https://makuba.mattilsynet.no/"
                linkOutside
                inline
              >
                brukerstøttesak
              </TextLink>
              <Text size="normal">
                (Fungerer best på PC på Mattilsynets nettverk)
              </Text>
            </div>
            <Text size="normal">
              Du kan også kontakte IKT-brukerstøtte på telefon eller e-post:
            </Text>
            <Row>
              <RowItem>
                <Text size="normal" margin={[0, 0.5, 0, 0]}>
                  Telefon:
                </Text>
              </RowItem>
              <TextLink href="tel:22 77 88 99">22 77 88 99</TextLink>
            </Row>
            <Row>
              <RowItem>
                <Text size="normal" margin={[0, 0.5, 0, 0]}>
                  E-post:
                </Text>
              </RowItem>
              <TextLink href="mailto:99@mattilsynet.no">
                99@mattilsynet.no
              </TextLink>
            </Row>
          </Column>
          <Column spacing={1}>
            <Header as="h2" size="heading3">
              Status
            </Header>
            <Row>
              <RowItem>
                <Text size="normal" margin={[0, 0.5, 0, 0]}>
                  Server:
                </Text>
              </RowItem>
              <StatusIndicator checked={apiStatus.checked} ok={apiStatus.ok} />
            </Row>
          </Column>
          <Column spacing={1}>
            <Header as="h2" size="heading3">
              Version
            </Header>
            <Text size="normal">{buildInfo.version}</Text>
          </Column>
        </Column>
      </ShadowBox>
    </GridItem>
  </Grid>
)
